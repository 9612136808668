<template>
    <div class="product_page">
        <div class="product_container" v-if="productActiveIndex>=0">
            <div class="product_main_wrap">
                <div class="pagination_wrap border_top_bottom flex_col flex_justify_between">
                    <img class="pagination_tag" src="@/assets/pc/img/pagination_tag.png" alt="">
                    <img class="prev_tag clickable" src="@/assets/pc/img/prev_3.png" @click="productSwiper.slidePrev()" alt="">
                    <img class="next_tag clickable" src="@/assets/pc/img/next_3.png" @click="productSwiper.slideNext()" alt="">
                </div>
                <div class="product_img_wrap">
                    <div class="temp_border w_h_100 absolute_l_t"></div>
                    <transition name="fade" v-for="(item, index) of productObject.productImgs"
                        :key="index">
                        <img v-show="swiperActiveIndex == index" class="product_img w_h_100 absolute_l_t" :src="item"
                            alt="">
                    </transition>
                </div>
                <div class="product_detail_wrap border_top_bottom flex_col">
                    <div class="product_name_wrap border_bottom">
                        <div class="product_name border_right flex_col flex_align_start">
                            <h2>DO IT NOW.</h2>
                            <h1>{{productObject.name_cn}}</h1>
                        </div>
                        <div class="buy_btn w_h_100 flex_center_center">
                            <img src="@/assets/pc/img/buy_tag.png" class="clickable" alt="" @click="handleBuyBtn">
                        </div>
                    </div>
                    <div class="choose_wrap border_bottom">
                        <div class="number_wrap border_right flex_align_center">
                            <span class="number_text">数量:</span>
                            <div class="choose_content">
                                <span v-for="(item, index) of productObject.numberList"
                                    :key="index">{{item}}</span>
                            </div>
                        </div>
                        <div class="is_choose w_h_100 flex_center_center">可选购</div>
                    </div>
                    <div class="distribution w_h_100 flex_center_center">
                        中国大陆地区尊享顺丰免费配送
                    </div>
                </div>
            </div>
            <div class="product_secondary_wrap">
                <div class="empty border_bottom"></div>
                <div class="product_img_wrap">
                    <swiper ref="productSwiper" :options="productSwiperOptions"
                        class="product_swiper w_h_100 swiper-no-swiping"
                        @slideChangeTransitionStart="slideChangeTransitionStart">
                        <swiper-slide class="product_swiper_slide flex_align_end flex_justify_center"
                            v-for="(item, index) of productObject.productImgs" :key="index">
                            <div class="temp_border w_h_100 absolute_l_t"></div>
                            <img :src="item" class="product_img w_h_100 absolute_l_t" alt="">
                        </swiper-slide>
                    </swiper>
                    <div class="handle_item left clickable" @click="productSwiper.slideNext()"></div>
                    <div class="handle_item right clickable" @click="productSwiper.slidePrev()"></div>
                </div>
                <P class="copywriting w_h_100 border_bottom flex_center_center">
                    {{productObject.intro}}</P>
            </div>
            <div class="specific_wrap border_bottom">
                <div class="specific_img_wrap">
                    <img class="specific_img w_h_100" :src="productObject.specificImg" alt="">
                    <img class="more_tag clickable" src="@/assets/pc/img/more_tag_3.png"
                        @click="$gotoPage('detail', {id: productObject.id})" alt="">
                </div>
                <div class="specific_content">
                    <p v-for="(item, index) of productObject.specificList">{{item}}</p>
                </div>
                <img class="specific_tag absolute_r_t" src="@/assets/pc/img/specific_tag.png" alt="">
            </div>
        </div>
        <div class="en_content">
            Integrate into a diverse youth culture</br>
            Create fun and interesting peripheral products</br>
            Help young people get rid of shackles</br>
            Be the most authentic and comfortable self
        </div>
        <div class="other_products_container">
            <h3>Other products</h3>
            <table class="other_products_wrap">
                <tr v-for="(item, index) of filteredList" :key="index">
                    <td class="category">
                        <div class="wrapper w_h_100 absolute_center" :class="categoryActiveIndex == index?'active':''">
                            <div class="category_text w_h_100  flex_center_center">
                                {{item.category}}
                            </div>
                        </div>
                    </td>
                    <td v-for="(childItem, childIndex) of item.data" :key="childIndex"
                        @click="handleSelectProduct(childItem.id)" class="clickable">
                        <div class="wrapper w_h_100 absolute_center">
                            <div class="product_wrap w_h_100 flex_col flex_align_center">
                                <img :src="childItem.coverImg" alt="">
                                <span class="en">{{childItem.name_en}}</span>
                                <span class="cn">{{childItem.name_cn}}</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <custom-foot></custom-foot>
        <div class="buyPop w_h_100 fixed_l_t" v-show="showBuyPop">
            <div class="mask w_h_100 absolute_l_t" @click='showBuyPop=false'></div>
            <div class="wrapper absolute_center flex_col flex_align_center">
                <div class="title">
                    请使用微信扫描<br>
                    下方小程序码前往购买
                </div>
                <img class="qrcode"
                    v-if="productObject" :src="productObject.appletCode" alt="">
                <img class="close_tag clickable" @click='showBuyPop=false' src="@/assets/pc/img/close.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>

    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'

    import { pcCosBaseUrl, baseCosBaseUrl } from '@/config/evn.js'

    export default {
        name: 'product',
        data() {
            return {
                productActiveIndex: -1,
                swiperActiveIndex: 0,
                showBuyPop: false,
                productSwiperOptions: {
                    slidesPerView: '3',
                    autoplay: false,
                    loop: true
                },
                allProductsList: [
                    {
                        id: 1,
                        address: 'https://mall.jd.com/view_search-2191562-18002803-99-1-20-1.html',
                        appletCode: '',
                        name_cn: '螺纹颗粒款',
                        name_en: 'Thread Particle',
                        numberList: [
                            '3只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        specificList: [
                            '冀械注准20172660292\n(医疗器械注册证号)',
                            '成分：天然橡胶',
                            '标称宽度：52mm±2mm',
                            '长度：≥170mm',
                            '型号：光面型',
                            '性能 ：爆破体积≥18.0L，爆破压力≥1.0kPa',
                            '有效期限 ：本品有限期为5年',
                            '储存条件 ：避孕套请保持完整的密封包装，并储存在凉爽干燥，不受阳光直射的地方对天然橡胶过敏者请勿使用'
                        ],
                        specificImg: pcCosBaseUrl + 'product_01_specific.png',
                        coverImg: pcCosBaseUrl + 'product_01_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_01_imgs_1.png',
                            pcCosBaseUrl + 'product_01_imgs_2.png',
                            pcCosBaseUrl + 'product_01_imgs_3.png'
                        ]
                    }, {
                        id: 2,
                        address: 'https://mall.jd.com/view_search-2191562-18002801-99-1-20-1.html',
                        appletCode: '',
                        name_cn: '超薄款',
                        name_en: 'Ultra Thin',
                        numberList: [
                            '9只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        specificList: [
                            '冀械注准20172660292\n(医疗器械注册证号)',
                            '成分：天然橡胶',
                            '标称宽度：52mm±2mm',
                            '长度：≥170mm',
                            '型号：光面型',
                            '性能 ：爆破体积≥18.0L，爆破压力≥1.0kPa',
                            '有效期限 ：本品有限期为5年',
                            '储存条件 ：避孕套请保持完整的密封包装，并储存在凉爽干燥，不受阳光直射的地方对天然橡胶过敏者请勿使用'
                        ],
                        specificImg: pcCosBaseUrl + 'product_02_specific.png',
                        coverImg: pcCosBaseUrl + 'product_02_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_02_imgs_1.png',
                            pcCosBaseUrl + 'product_02_imgs_2.png',
                            pcCosBaseUrl + 'product_02_imgs_3.png'
                        ]
                    }, {
                        id: 3,
                        address: 'https://mall.jd.com/view_search-2191562-18002799-99-1-20-1.html',
                        appletCode: '',
                        name_cn: '玻尿酸款',
                        name_en: 'Hyaluronic Acid',
                        numberList: [
                            '3只装',
                            '6只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        specificList: [
                            '冀械注准20172660292\n(医疗器械注册证号)',
                            '成分：天然橡胶',
                            '标称宽度：52mm±2mm',
                            '长度：≥170mm',
                            '型号：光面型',
                            '性能 ：爆破体积≥18.0L，爆破压力≥1.0kPa',
                            '有效期限 ：本品有限期为5年',
                            '储存条件 ：避孕套请保持完整的密封包装，并储存在凉爽干燥，不受阳光直射的地方对天然橡胶过敏者请勿使用'
                        ],
                        specificImg: pcCosBaseUrl + 'product_03_specific.png',
                        coverImg: pcCosBaseUrl + 'product_03_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_03_imgs_1.png',
                            pcCosBaseUrl + 'product_03_imgs_2.png',
                            pcCosBaseUrl + 'product_03_imgs_3.png'
                        ]
                    }, {
                        id: 4,
                        address: 'https://mall.jd.com/view_search-2191562-18002800-99-1-20-1.html',
                        appletCode: '',
                        name_cn: '超润超薄款',
                        name_en: 'Super Moist Ultra-Thin',
                        numberList: [
                            '3只装',
                            '6只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        specificList: [
                            '冀械注准20172660292\n(医疗器械注册证号)',
                            '成分：天然橡胶',
                            '标称宽度：52mm±2mm',
                            '长度：≥170mm',
                            '型号：光面型',
                            '性能 ：爆破体积≥18.0L，爆破压力≥1.0kPa',
                            '有效期限 ：本品有限期为5年',
                            '储存条件 ：避孕套请保持完整的密封包装，并储存在凉爽干燥，不受阳光直射的地方对天然橡胶过敏者请勿使用'
                        ],
                        specificImg: pcCosBaseUrl + 'product_04_specific.png',
                        coverImg: pcCosBaseUrl + 'product_04_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_04_imgs_1.png',
                            pcCosBaseUrl + 'product_04_imgs_2.png',
                            pcCosBaseUrl + 'product_04_imgs_3.png'
                        ]
                    }, {
                        id: 5,
                        address: 'https://mall.jd.com/view_search-2191562-18002802-99-1-20-1.html',
                        appletCode: '',
                        name_cn: '持久延迟款',
                        name_en: 'Long Delay Payment',
                        numberList: [
                            '3只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        specificList: [
                            '冀械注准20172660292\n(医疗器械注册证号)',
                            '成分：天然橡胶',
                            '标称宽度：52mm±2mm',
                            '长度：≥170mm',
                            '型号：光面型',
                            '性能 ：爆破体积≥18.0L，爆破压力≥1.0kPa',
                            '有效期限 ：本品有限期为5年',
                            '储存条件 ：避孕套请保持完整的密封包装，并储存在凉爽干燥，不受阳光直射的地方对天然橡胶过敏者请勿使用'
                        ],
                        specificImg: pcCosBaseUrl + 'product_05_specific.png',
                        coverImg: pcCosBaseUrl + 'product_05_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_05_imgs_1.png',
                            pcCosBaseUrl + 'product_05_imgs_2.png',
                            pcCosBaseUrl + 'product_05_imgs_3.png'
                        ]
                    }, {
                        id: 6,
                        address: '',
                        appletCode: baseCosBaseUrl + 'product_06_applet_code.png',
                        name_cn: '润滑剂',
                        name_en: 'Lubricant',
                        numberList: [
                            '1盒装'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        specificList: [
                            '品牌：解套GETO',
                            '产品名称：GETO解套润滑剂-ci溜ci溜',
                            '主要成分：水、甘油、丙二醇、羟乙基纤维素',
                            '储存：存放于凉爽干燥处，避免阳光照晒',
                            '功能：可单独使用，也可用情趣玩具上作表面润滑',
                            '形状：如图',
                            '尺寸：100×35mm',
                            '包装：透明小盒'
                        ],
                        specificImg: pcCosBaseUrl + 'product_06_specific.png',
                        coverImg: pcCosBaseUrl + 'product_06_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_06_imgs_1.png',
                            pcCosBaseUrl + 'product_06_imgs_2.png',
                            pcCosBaseUrl + 'product_06_imgs_3.png'
                        ]
                    }, {
                        id: 7,
                        address: '',
                        appletCode: baseCosBaseUrl + 'product_07_applet_code.png',
                        name_cn: '钥匙扣',
                        name_en: 'Key Buckle',
                        numberList: [
                            '1个装'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        specificList: [
                            '材质：亚克力\刺绣',
                            '形状：如图',
                            '颜色：蓝色',
                            '包装：单个入透明小袋',
                            '用途：想挂哪就挂哪',
                            '对以上成分过敏者请勿使用'
                        ],
                        specificImg: pcCosBaseUrl + 'product_07_specific.png',
                        coverImg: pcCosBaseUrl + 'product_07_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_07_imgs_1.png',
                            pcCosBaseUrl + 'product_07_imgs_2.png',
                            pcCosBaseUrl + 'product_07_imgs_3.png'
                        ]
                    }, {
                        id: 8,
                        address: '',
                        appletCode: baseCosBaseUrl + 'product_08_applet_code.png',
                        name_cn: '丝巾',
                        name_en: 'Silk Scarf',
                        numberList: [
                            '850mm'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        specificList: [
                            '品牌：GETO解套',
                            '产品名称：GETO解套丝巾一一丝不挂',
                            '材质：仿真丝',
                            '形状：如图',
                            '颜色：蓝色',
                            '包装：透明小袋',
                            '功能：多功能'
                        ],
                        specificImg: pcCosBaseUrl + 'product_08_specific.png',
                        coverImg: pcCosBaseUrl + 'product_08_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_08_imgs_1.png',
                            pcCosBaseUrl + 'product_08_imgs_2.png',
                            pcCosBaseUrl + 'product_08_imgs_3.png'
                        ]
                    }, {
                        id: 9,
                        address: '',
                        appletCode: baseCosBaseUrl + 'product_09_applet_code.png',
                        name_cn: '胸贴',
                        name_en: 'Chest Paste',
                        numberList: [
                            '8对装'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        specificList: [
                            '品牌：解套GETO',
                            '产品名称：GETO解套胸贴—XX胸贴',
                            '材质：PU',
                            '形状：X形',
                            '寿命：一次性',
                            '尺寸：65×65mm',
                            '颜色：蓝色/粉色',
                            '包装：透明小袋/白卡'
                        ],
                        specificImg: pcCosBaseUrl + 'product_09_specific.png',
                        coverImg: pcCosBaseUrl + 'product_09_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_09_imgs_1.png',
                            pcCosBaseUrl + 'product_09_imgs_2.png',
                            pcCosBaseUrl + 'product_09_imgs_3.png'
                        ]
                    }, {
                        id: 10,
                        address: '',
                        appletCode: baseCosBaseUrl + 'product_10_applet_code.png',
                        name_cn: '福袋',
                        name_en: 'Blessing Bag',
                        numberList: [
                            '1只装'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        specificList: [
                            '品牌：解套GETO',
                            '产品名称：GETO解套福袋一福满多多',
                            '材质：布面/双面刺绣',
                            '形状：如图',
                            '尺寸：40×60mm',
                            '颜色：红色',
                            '包装：透明小袋/白卡'
                        ],
                        specificImg: pcCosBaseUrl + 'product_10_specific.png',
                        coverImg: pcCosBaseUrl + 'product_10_cover.png',
                        productImgs: [
                            pcCosBaseUrl + 'product_10_imgs_1.png',
                            pcCosBaseUrl + 'product_10_imgs_2.png',
                            pcCosBaseUrl + 'product_10_imgs_3.png'
                        ]
                    }
                ],
                filteredList: [
                    {
                        'category': 'Condom',
                        'data': []
                    }, {
                        'category': 'Condom/periphery',
                        'data': []
                    }, {
                        'category': 'Interesting surroundings',
                        'data': []
                    }
                ]
            }
        },
        created() {
            let id = this.$route.query.id
            if (id) {
                this.initById(JSON.parse(id))
            }
        },
        computed: {
            productSwiper() {
                return this.$refs.productSwiper.swiper
            },
            categoryActiveIndex() {
                let index = Math.floor(this.productActiveIndex / 3)
                if (index > this.filteredList.length - 1) return this.filteredList.length - 1
                return index
            },
            productObject() {
                let productActiveIndex = this.productActiveIndex
                if (productActiveIndex < 0) return undefined
                return this.allProductsList[productActiveIndex]
            }
        },
        watch: {
            productActiveIndex: {
                handler(newVal, oldVal) {
                    let filteredList = JSON.parse(JSON.stringify(this.allProductsList))
                    filteredList.splice(newVal, 1)
                    this.filteredList.forEach((val, index) => {
                        if (filteredList.length > 0) val.data = filteredList.splice(0, 3)
                    })
                }
            }
        },
        methods: {
            initById(id) {
                this.productActiveIndex = -1
                let allProductsList = this.allProductsList
                let maxLen = allProductsList.length
                for (let i = 0; i < maxLen; i++) {
                    if (allProductsList[i].id === id) {
                        this.productActiveIndex = i
                        break
                    }
                }
            },
            slideChangeTransitionStart() {
                this.swiperActiveIndex = this.productSwiper.activeIndex % this.productObject.productImgs.length
            },
            handleSelectProduct(id) {
                this.$router.push({
                    path: '/product',
                    query: {
                        id
                    }
                })
            },
            handleBuyBtn() {
                let address = this.productObject.address
                if (address) return this.$windowOpen(address)
                this.showBuyPop = true
            }
        },
        components: {
            swiper,
            swiperSlide
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/style/mixin.scss';

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .3s;
    }

    .fade-enter,
    .fade-leave-to

    /* .fade-leave-active below version 2.1.8 */
        {
        opacity: 0;
    }

    .product_page {
        padding-top: formatPX_1920(167px);

        .buyPop {
            z-index: 10;
            .mask {
                background-color: rgba(102, 102, 102, 0.3);
            }
            .wrapper {
                width: formatPX_1920(400px);
                height: formatPX_1920(400px);
                background-color: #fff;
                padding-top: formatPX_1920(40px);
                .title {
                    line-height: formatPX_1920(48px);
                    font-family: SourceHanSansCN-Medium;
                    font-size: formatPX_1920(24px);
                }
                .qrcode {
                    width: formatPX_1920(200px);
                    height: formatPX_1920(200px);
                    margin-top: formatPX_1920(20px);
                }
                .close_tag {
                    position: absolute;
                    right: formatPX_1920(30px);
                    top: formatPX_1920(30px);
                    width: formatPX_1920(30px);
                    height: formatPX_1920(30px);
                }
            }
        }

        .border_top_bottom {
            border-top: formatPX_1920(3px) solid #2C2C2C;
            border-bottom: formatPX_1920(3px) solid #2C2C2C;
        }

        .border_bottom {
            border-bottom: formatPX_1920(3px) solid #2C2C2C;
        }

        .border_right {
            border-right: formatPX_1920(3px) solid #2C2C2C;
        }

        .temp_border {
            border: formatPX_1920(3px) solid #2C2C2C;
        }

        .product_container {
            .product_main_wrap {
                display: flex;
                height: formatPX_1920(696px);

                .pagination_wrap {
                    width: formatPX_1920(210px);
                    height: 100%;
                    flex-shrink: 0;
                    padding: formatPX_1920(58px) 0 formatPX_1920(58px) formatPX_1920(90px);

                    .pagination_tag {
                        width: formatPX_1920(72px);
                        height: formatPX_1920(327px);
                    }

                    .prev_tag,
                    .next_tag {
                        width: formatPX_1920(72px);
                        height: formatPX_1920(68px);
                    }
                }

                .product_img_wrap {
                    position: relative;
                    width: formatPX_1920(569px);
                    height: formatPX_1920(696px);
                    flex-shrink: 0;

                    img {
                        width: formatPX_1920(569px);
                        height: formatPX_1920(696px);
                    }
                }

                .product_detail_wrap {
                    width: 100%;

                    .product_name_wrap {
                        display: flex;
                        width: 100%;
                        height: formatPX_1920(260px);
                        flex-shrink: 0;

                        .product_name {
                            width: formatPX_1920(445px);
                            flex-shrink: 0;
                            padding: formatPX_1920(59px) 0 0 formatPX_1920(90px);

                            h2 {
                                line-height: formatPX_1920(51px);
                                font-family: HurmeGeometricSans3-SemiBold;
                                font-size: formatPX_1920(42px);
                                color: #BFBFBF;
                            }

                            h1 {
                                font-family: SourceHanSansCN-Bold;
                                font-size: formatPX_1920(60px);
                                margin-top: formatPX_1920(30px);
                            }
                        }

                        .buy_btn {
                            img {
                                width: formatPX_1920(262px);
                                height: formatPX_1920(68px);
                            }
                        }
                    }

                    .choose_wrap {
                        display: flex;
                        width: 100%;
                        height: formatPX_1920(215px);
                        flex-shrink: 0;

                        .number_wrap {
                            width: formatPX_1920(722px);
                            flex-shrink: 0;
                            padding: 0 formatPX_1920(90px);

                            .number_text {
                                /* width: formatPX_1920(126px); */
                                flex-shrink: 0;
                                line-height: formatPX_1920(42px);
                                font-family: SourceHanSansCN-Bold;
                                font-size: formatPX_1920(42px);
                            }

                            .choose_content {
                                display: flex;
                                flex-grow: 0;
                                flex: 1;
                                font-family: SourceHanSansCN-Regular;
                                font-size: formatPX_1920(30px);
                                overflow: hidden;

                                span {
                                    width: formatPX_1920(160px);
                                    line-height: formatPX_1920(56px);
                                    border: formatPX_1920(2px) solid #2C2C2C;
                                    border-radius: formatPX_1920(38px);
                                    margin-left: formatPX_1920(45px);
                                }
                            }
                        }
                    }

                    .distribution {
                        font-family: SourceHanSansCN-Regular;
                        font-size: formatPX_1920(36px);
                        color: #888888;
                    }
                }
            }

            .product_secondary_wrap {
                display: flex;
                height: formatPX_1920(348px);

                .empty {
                    width: formatPX_1920(210px);
                    height: 100%;
                    flex-shrink: 0;
                }

                .product_img_wrap {
                    position: relative;
                    width: formatPX_1920(569px);
                    height: 100%;
                    flex-shrink: 0;
                    overflow: hidden;

                    .product_swiper {
                        width: formatPX_1920(853.5px);
                        transform: translate3d(formatPX_1920(-284.5px), 0, 0);
                    }
                    .handle_item {
                        position: absolute;
                        top: 0;
                        z-index: 1;
                        width: 50%;
                        height: 100%;
                        background-color: transparent;
                        &.left {
                            left: 0;
                        }
                        &.right {
                            right: 0;
                        }
                    }

                }

                .copywriting {
                    line-height: formatPX_1920(60px);
                    font-family: SourceHanSansCN-Medium;
                    font-size: formatPX_1920(30px);
                    text-align: left;
                    padding: 0 formatPX_1920(85px);
                }
            }

            .specific_wrap {
                position: relative;
                display: flex;
                height: formatPX_1920(730px);

                .specific_img_wrap {
                    position: relative;
                    width: formatPX_1920(779px);
                    flex-shrink: 0;

                    .more_tag {
                        position: absolute;
                        right: formatPX_1920(16px);
                        bottom: formatPX_1920(48px);
                        width: formatPX_1920(153px);
                        height: formatPX_1920(45px);
                    }
                }

                .specific_content {
                    width: 100%;
                    line-height: formatPX_1920(60px);
                    font-family: SourceHanSansCN-Medium;
                    font-size: formatPX_1920(24px);
                    text-align: left;
                    white-space: pre-wrap;
                    padding: formatPX_1920(90px) formatPX_1920(140px) 0 formatPX_1920(90px);
                }

                .specific_tag {
                    width: formatPX_1920(272px);
                    height: formatPX_1920(236px);
                }
            }
        }

        .en_content {
            height: formatPX_1920(710px);
            line-height: formatPX_1920(60px);
            font-family: HurmeGeometricSans3-SemiBold;
            font-size: formatPX_1920(36px);
            color: #0000FF;
            background-color: #F6F6F6;
            padding: formatPX_1920(208px) formatPX_1920(500px) 0;
        }

        .other_products_container {
            margin-bottom: formatPX_1920(155px);

            h3 {
                line-height: formatPX_1920(240px);
                font-family: HurmeGeometricSans3-Black;
                font-size: formatPX_1920(60px);
            }

            .other_products_wrap {
                width: 100%;
                font-family: HurmeGeometricSans3-Bold;
                border: formatPX_1920(2px) solid #2C2C2C;
                table-layout: fixed;
                word-break: break-all;

                tr {
                    height: formatPX_1920(780px);
                }

                td {
                    position: relative;
                    border: formatPX_1920(2px) solid #2C2C2C;
                }

                .category {
                    position: relative;
                    width: formatPX_1920(210px);


                    .wrapper {
                        &.active {
                            color: #fff;
                            background-color: #1442FF;
                        }
                    }

                    .category_text {
                        font-size: formatPX_1920(44px);
                        white-space: nowrap;
                        transform: rotate(90deg);


                    }
                }

                .product_wrap {
                    padding-top: formatPX_1920(128px);

                    img {
                        width: formatPX_1920(388px);
                        height: formatPX_1920(504px);
                    }

                    .en {
                        font-size: formatPX_1920(30px);
                        margin: formatPX_1920(24px) 0 formatPX_1920(10px);
                    }

                    .cn {
                        font-family: SourceHanSansCN-Medium;
                        font-size: formatPX_1920(24px);
                    }
                }

            }

            /* .other_products_wrap {
                position: relative;
                height: formatPX_1920(2354px);

                .title_wrap {
                    width: formatPX_1920(2354px);
                    height: formatPX_1920(206px);
                    font-family: HurmeGeometricSans3-Bold;
                    font-size: formatPX_1920(44px);
                    display: flex;
                    transform-origin: 0 100%;
                    transform: rotate(90deg) translate3d(formatPX_1920(-206px), 0, 0);
                    border-left: formatPX_1920(2px) solid #2C2C2C;
                    border-bottom: formatPX_1920(2px) solid #2C2C2C;
                    span {
                        width: formatPX_1920(784px);
                        border-right: formatPX_1920(2px) solid #2C2C2C;
                        flex-shrink: 0;
                        flex-grow: 0;
                        &.actived {
                            color: #fff;
                            background-color: #1442FF;
                        }
                    }
                }
                .other_products_content {
                    position: absolute;
                    left: formatPX_1920(206px);
                    right: 0;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    border-top: formatPX_1920(2px) solid #2C2C2C;
                    border-right: formatPX_1920(2px) solid #2C2C2C;

                    .product_wrap {
                        width: 33.33333%;
                        height: formatPX_1920(784px);;
                        border-bottom: formatPX_1920(2px) solid #2C2C2C;
                        border-left: formatPX_1920(2px) solid #2C2C2C;
                        flex-shrink: 0;
                        flex-grow: 0;
                    }
                }

            } */
        }
    }
</style>